<template>
  <div>
      <AuthorizationDetail/>
  </div>
</template>

<script>
import AuthorizationDetail from './AuthorizationDetail'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'AuthorizationDetailLayout',
  components: { 
    AuthorizationDetail
  },
  mounted() {
    if (!this.$route.params.authorization) {
      this.onBack();
    } else {
      this.getAuthorizationDetail(this.$route.params.authorization)
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
  },
  methods: {
    ...authorizationModule.mapActions(['getAuthorizationDetail', 'setSingleAuthorization']),
    onBack() {
      this.setSingleAuthorization({})
      this.$router.push('/home')
    },
   }  
}
</script>