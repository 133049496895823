<template>
  <v-container class="px-0">
    <v-row no-gutters v-for="(item, index) in medicalProcedures" :key="index">
      <v-col>
        <v-row no-gutters>
          <v-col >
            <span class="text-caption">{{ item.productName }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MedicalProcedures',
  props: {
    medicalProcedures: {
      type: Array,
      default: () => []
    }
  }
}
</script>