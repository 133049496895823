<template>
  <v-container fluid>
    <v-row v-if="Object.keys(authorization).length === 0">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          class="text-center"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else>
      <div v-if="authorization.coverageType === 'CREDIT_CARD_BALANCE' && sponsorSlug === 'mx-finvivir'">
        <CreditCardBalanceInformation></CreditCardBalanceInformation>
      </div>
      <div v-else-if="dynamicCoverages.includes(authorization.coverageType)">
        <DynamicInformation></DynamicInformation>
      </div>
      <div v-else>
        <Claim></Claim>
        
        <Invoice></Invoice>
        
        <Prescription></Prescription>

        <OtherFiles></OtherFiles>

        <Result></Result>      
      </div>
    </div>
  </v-container>
</template>

<script>
import Claim from './section/Claim'
import Invoice from './section/Invoice'
import Prescription from './section/Prescription'
import OtherFiles from './section/OtherFiles'
import Result from './section/Result'
import CreditCardBalanceInformation from './CreditCardBalanceInformation'
import DynamicInformation from './section/DynamicInformation.vue'
import { dynamicCoverages } from '@/utils'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'Information',
  components: { Claim, Invoice, Prescription, OtherFiles, Result, CreditCardBalanceInformation, DynamicInformation },
  data: () => ({
    dynamicCoverages
  }),
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'sponsorSlug']),
  }
}
</script>