<template>
  <v-container class="px-0" v-if="showInvoiceSection">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="my-1">
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ $t('authorizationDetail.information.invoice.label') }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-3" v-if="claim && claim.authorizedAmount">
          <v-col class="grey lighten-5 px-3">
            <v-row no-gutters>
              <v-col style="line-height: 1.2">
                <span class="text-button osg-gray--text">
                  {{ $t('authorizationDetail.information.invoice.authorizedAmount') }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters >
              <v-col style="line-height: 1.2">
                <span class="text-subtitle-2 osg-black--text font-weight-bold">
                  {{ claim.currencyCode }} {{ claim.authorizedAmount.toLocaleString() }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-2" v-if="products && products.length > 0">
          <v-col>
            <v-row no-gutters>
              <v-col>
                <span class="text-subtitle-2 osg-gray--text">
                  {{ $t('authorizationDetail.information.invoice.productsTitle') }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters v-for="(product, index) in products" :key="index">
              <v-col>
                <v-row no-gutters>
                  <v-col cols="8" md="10">
                    <span class="text-caption osg-black--text">
                      {{ product.name }}
                    </span>
                  </v-col>
                  <v-col cols="4" md="2" class="text-right">
                    <span 
                      :class="['text-button', 
                        {'primary--text': isApproved(product.status),
                        'error--text': !isApproved(product.status)}]">
                      {{ $t(getProductStatus(product.status)) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="product.reason">
                  <v-col cols="8" md="10" style="line-height: 1.2">
                    <span class="text-caption osg-gray--text">
                      {{ product.reason }}
                    </span>
                  </v-col>
                </v-row>
                <v-divider v-if="index !== products.length - 1" class="my-2" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2" v-if="invoiceFiles && invoiceFiles.length > 0">
          <v-col class="grey lighten-4">
            <v-row class="ma-auto" no-gutters>
              <v-col cols="12">
                <DisplayFiles :files="invoiceFiles" source="DETAIL"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DisplayFiles from '../../files/DisplayFiles'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'Invoice',
  components: {DisplayFiles},
  methods: {
    getProductStatus(status) {
      const statusLabel = 'authorizationDetail.information.invoice.productStatus'.concat(
        status === 'APPROVED' ? '.approved' : '.rejected'
      )
      return statusLabel
    },
     isApproved(status) {
      return status === 'APPROVED'
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    claim() {
      let claim = null;
      if (this.authorization.isReimbursement && this.authorization.claims.length > 0) {
        claim = this.authorization.claims[0]
      }
      return claim
    },
    products() {
      return this.authorization.products
    },
    invoiceFiles() {
      return this.authorization.files && this.authorization.files
      .filter(file => file.fieldSlug.toUpperCase().includes('INVOICE'))
      .map(file => { return {
        ...file,
        name: file.originalFileName,
        type: file.extension,
      }})
    },
    showInvoiceSection() {
      return (this.claim && this.claim.authorizedAmount || this.products && this.products.length > 0 || this.invoiceFiles && this.invoiceFiles.length > 0)
    },
  }
}
</script>