<template>
  <v-container class="px-0">
    <v-row v-if="!comments.length" align="center" justify="center">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 8" class="height-300 d-flex justify-center align-center">
        <v-icon color="osg-gray" size="40">$osg-message-circle</v-icon>
        <span class="text-caption ml-2 no-comment-text">
          {{ $t("authorizationDetail.observations.comments.noComments") }}
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
      <v-col cols="12">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <span class="text-caption osg-gray--text my-2">
              {{ headerDate }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        ref="commentBox"
        cols="12"
        class="height-300 overflow-y"
        @scroll.passive="handleScroll"
      >
        <v-row
          no-gutters
          v-for="(comment, index) in comments"
          :key="index"
          :align="comment.isBeneficiaryComment ? 'end' : 'start'"
          :justify="comment.isBeneficiaryComment ? 'end' : 'start'"
        >
          <v-col cols="auto" class="pa-2">
            <div
              :class="[
                comment.isBeneficiaryComment
                  ? 'osg-gray-comment rounded-bl-lg ml-8'
                  : 'osg-blue-comment rounded-br-lg mr-8',
              ]"
              class="px-4 rounded-tl-lg rounded-tr-lg"
            >
              <v-row>
                <v-col>
                  <div style="width:100%;">
                    <span
                      class="text-caption text-wrap osg-text-message--text"
                      ref="datetimeMessage"
                      :datetime="comment.createdAt"
                    >
                      <strong>{{ comment.createdAt | dateTime }}</strong>
                    </span>
                    <span class="text-caption text-wrap osg-text-message--text">
                      {{ $t("authorizationDetail.observations.comments.byLabel") }}
                    </span>
                    <span class="text-caption text-wrap osg-text-message--text">
                      <strong>{{ $t(commentByText(comment.isBeneficiaryComment)) }}</strong>
                    </span>
                  </div>
                  <span class="text-caption text-wrap d-flex align-end osg-text-message--text">
                    {{ comment.comment }}
                  </span>
                  <v-row no-gutters>
                    <v-col cols="12" v-if="comment.metadataComment">
                      <span class="text-caption text-wrap osg-black--text d-flex align-end mt-4">
                        <strong>{{ getFallbackLabel(comment.metadataComment) }}</strong>
                      </span>
                      <ul class="pl-0">
                        <li v-for="(commentFile, index) in getCommentfiles(comment.metadataComment)" :key="index" class="text-caption osg-text-message--text text-wrap">
                          {{ commentFile.name }}
                        </li>
                      </ul>
                    </v-col>                    
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="commentText"
          :label="$t('authorizationDetail.observations.comments.inputLabel')"
          :rules="[(value) => rules.maxLength(value, 512)]"
          dense
          hide-details="auto"
          outlined
          class="rounded text-caption pt-1"
          single-line
          persistent-hint
          counter="512"
          auto-grow
          rows="1"
          row-height="5"
        >
          <template v-slot:append>
            <v-btn
              icon
              :loading="authorizationCommentLoading"
              color="primary"
              class="mb-2"
              :disabled="!commentText.length"
              @click="sendComment()"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { camelizeKeys } from 'humps'
import { inputRules } from "@/utils";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const authorizationModule = createNamespacedHelpers("authorization");

export default {
  name: "Observations",
  data: () => ({
    rules: inputRules,
    commentText: "",
    headerDate: "",
    hasScroll: false
  }),
  mounted() {
    this.scrollToEnd();
  },
  filters: {
    dateTime(date) {
      return moment(date).locale('es').format("MMMM DD, YYYY HH:mm:ss");
    },
    time(dateTime) {
      return moment(dateTime).format("hh:mm A");
    },
    date(dateTime) {
      return moment(dateTime).format("DD/MM/YYYY");
    }
  },
  computed: {
    ...authorizationModule.mapGetters([
      "authorization",
      "authorizationCommentLoading",
    ]),
    comments() {
      return this.sortCommentsByDate();
    }
  },
  methods: {
    ...authorizationModule.mapActions(["sendBeneficiaryComment", "setError"]),
    sortCommentsByDate() {
      const comments = this.authorization.comments;
      return comments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
    },
    async sendComment() {
      try {
        await this.sendBeneficiaryComment(this.commentText);
        this.commentText = "";
        this.scrollToEnd();
      } catch (error) {
        const { response } = error;
        this.setError({
          hasError: true,
          error: response && response.data.message,
        });
      }
    },
    scrollToEnd() {
      const container = this.$refs.commentBox;
   
      if(container != undefined) {
        container.scrollTop = container.scrollHeight;
        this.hasScroll = container.scrollHeight != 300;
      }
    },
    handleScroll(e) {
      const messages = this.$refs.datetimeMessage;
      messages.forEach((message) => {
        if (e.target.scrollTop >= message.offsetTop) {
          this.setHeaderDate(message.getAttribute("datetime"));
        }
      });
    },
    setHeaderDate(dateTime) {
      const dateWithFilter = this.$options.filters.date(dateTime);
      if (moment(dateTime).isSame(moment(), "day")) {
        this.headerDate = this.$tc('authorizationDetail.observations.comments.headerDatePrefix', dateWithFilter, {date: dateWithFilter});
      } else {
        this.headerDate = dateWithFilter;
      }
    },
    getCommentfiles(value) {
      const metadataComment = this.deserializeMetadataComment(value);
      return metadataComment.commentFiles || [];   
    },
    getFallbackLabel(value) {
      const metadataComment = this.deserializeMetadataComment(value);
      return metadataComment.fallbackLabel;
    },
    deserializeMetadataComment(value){
      const deserializeMetadataComment = JSON.parse(value)
      return  camelizeKeys(deserializeMetadataComment);
    },
    commentByText(isFromBeneficiary){
      if(isFromBeneficiary){
        return "authorizationDetail.observations.comments.byBeneficiary"
      }
      return "authorizationDetail.observations.comments.by";
    }
  },
};
</script>
<style scoped>
.height-300 {
  height: 300px;
}
.overflow-y {
  overflow-y: scroll;
  position: relative;
}
.no-comment-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--v-osg-gray-base) !important;
}
::v-deep .v-textarea textarea {
  line-height: 1.28 !important;
  padding: 8px 12px 8px 0px !important;
}
li {
    list-style: none;
}

li:before {
    content:"·";
    font-size:24px;
    vertical-align:middle;
    line-height:20px;
}
</style>