<template>
  <v-container class="pt-0 px-0" v-if="showSettlementFilesSection">
    <v-divider class="mb-3"/>
    <v-row class="mx-auto pt-3">
      <v-col cols="12" class="settlement-card pr-0">
        <v-row no-gutters class="my-1">
          <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : ''">
            <v-row class="mx-auto">
              <v-col cols="12" class="py-1">
                <span class="text-subtitle-1 secondary--text">
                  {{ $t('authorizationDetail.information.settlementFiles.title') }}
                </span>
              </v-col>
              <v-col cols="12" class="py-0" v-if="settlementFiles && settlementFiles.length === 0">
                <p class="text-caption osg-black--text">
                  {{ $t('authorizationDetail.information.settlementFiles.message') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col style="max-width: 150px" class="d-flex justify-center align-center">
            <v-btn 
              depressed
              v-if="settlementFilesField"
              class="rounded"
              color="primary"
              @click="onAddFiles"
              width="130"
            >
              <span class="text-button">{{ $t('authorizationDetail.information.settlementFiles.addButtonLabel') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2" v-if="settlementFiles && settlementFiles.length > 0">
          <v-col class="grey lighten-4">
            <v-row class="ma-auto" no-gutters>
              <v-col cols="12">
                <DisplayFiles :files="settlementFiles" source="DETAIL" backgroundFiles="background: #e7effe;"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <AddFileModal ref="addFileModal" :field="settlementFilesField"></AddFileModal>
    </v-row>
  </v-container>
</template>

<script>
import DisplayFiles from '../../files/DisplayFiles'
import AddFileModal from '../../files/AddFileModal'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'SettlementFiles',
  components: { DisplayFiles, AddFileModal },
  methods: {
    ...authorizationModule.mapActions(['setFiles', 'setError']),
    onAddFiles() {
      this.setFiles([])
      this.setError({hasError: false, error: null})
      this.$refs.addFileModal && this.$refs.addFileModal.onShowModal();
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'authorizationForms']),
    settlementFiles() {
      return this.authorization.reimbursementFiles 
        && this.authorization.reimbursementFiles
          .filter(file => file.fieldSlug.toUpperCase().includes('SETTLEMENT_FILES'))
          .map(file => ({...file, name: file.originalFileName, type: file.extension}))
    },
    settlementFilesField() {
      const coverageType = this.authorization.coverageType;
      const coverageForm = this.authorizationForms.find(form => form.authorizationCoverage === coverageType)

      if (coverageForm) {
        const field = coverageForm.fields.find(field => field.slug.toUpperCase().includes('SETTLEMENT_FILES') && field.type === 'FILE_UPLOAD')
        return field
      }
      return null;
    },
    showSettlementFilesSection() {
      return (this.authorization.status === 'PAID' || this.authorization.status === 'CLAIMED') && this.settlementFilesField
    },
  }
}
</script>

<style lang="scss" scoped>
.settlement-card {
  background: #e7effe;
  border-radius: 10px;
}
</style>