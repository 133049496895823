<template>
  <v-container class="px-0">
    <v-data-table 
      :headers="headers"
      :items="productsArray"
      hide-default-footer
      >
      <template v-slot:[`item.productName`]="{item}">
        <v-row>
          <v-col cols="8" md="10">
            <span class="text-caption osg-black--text">{{ item.productName }}</span> 
          </v-col>
          <v-col cols="4" md="2" class="text-right">
            <span 
              :class="['text-button', 
                {'primary--text': item.isApproved,
                'error--text': !item.isApproved}]">
              {{ item.status }}
            </span>
          </v-col>
        </v-row>
        
      </template>
      <template v-slot:[`item.unitPrice`]="{ item }">
        <span class="text-caption">{{ item.currencyCode }} {{ item.unitPrice }}</span> 
      </template>
      <template v-slot:[`item.quantity`]="{item}">
        <span class="text-caption">{{ item.quantity }}</span> 
      </template>
      <template slot="body.append">
        <tr>
          <th> {{ $t('addAuthorization.customObjectFields.drugProduct.footer.total') }} </th>
          <th> {{ getTotalPrice() }}</th>
          <th> {{ getTotalQuantity() }}</th>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: 'DrugProduct',
  props: {
    products: {
      type: Array,
      default: () => []
    },
    authorizationProducs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getTotalPrice() {
      const total = this.productsArray.filter(product => product.isApproved).map(product => parseFloat(product.unitPrice)).reduce((prev, curr) => prev + curr, 0)
      return this.products[0].currencyCode.concat(' ').concat(total)
    },
    getTotalQuantity() {
      return this.productsArray.filter(product => product.isApproved).map(product => parseFloat(product.quantity)).reduce((prev, curr) => prev + curr, 0)
    },
    getProductStatus(status) {
      const statusLabel = 'authorizationDetail.information.invoice.productStatus'.concat(
        status === 'APPROVED' ? '.approved' : '.rejected'
      )
      return statusLabel
    },
     isApproved(status) {
      return status === 'APPROVED'
    },
  },
  computed: {
    productsArray() {
      const products = this.products.map(product => {
        const authorizationProduct = this.authorizationProducs.find(authorizationProduct => authorizationProduct.productId === product.id);
        if (!authorizationProduct) {
          return {...product}
        }

        return {
          ...product,
          status: this.$t(this.getProductStatus(authorizationProduct.status)),
          isApproved: this.isApproved(authorizationProduct.status)
        }
      })

      return products
    },
    headers() {
      return [
        {
          text: this.$t('addAuthorization.customObjectFields.drugProduct.headers.product'), 
          value: 'productName', 
          class: "secondary--text", 
          sortable: false
        },
        {
          text: this.$t('addAuthorization.customObjectFields.drugProduct.headers.unitPrice'), 
          value: 'unitPrice', 
          class: "secondary--text", 
          sortable: false
        },
        {
          text: this.$t('addAuthorization.customObjectFields.drugProduct.headers.quantity'), 
          value: 'quantity', 
          class: "secondary--text", 
          sortable: false
        }
      ]
    }
  }
}
</script>