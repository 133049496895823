<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-autocomplete :label="searchLabel"
          hide-no-data
          :items="products"
          v-model="selectedProduct"
          :search-input.sync="searchInput"
          item-text="productName"
          @change="selectProduct()"
          return-object
          outlined
          dense
          ref="searchProductInput"
          autocomplete="off"
          hide-details="auto"
          :loading="loading"
          no-filter>
          <template slot="append">
            <v-icon v-if="searching"
              class="black--text"
              @click.stop="clearSearch()">mdi-close-circle</v-icon>
            <v-icon v-else
              class="black--text">mdi-magnify</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <template v-if="value && value.length > 0">
      <v-row dense v-for="(product, index) of value" :key="index">
        <v-col cols="12" :sm="showInputs ? 6 : 12">
          <v-chip class="product-chip py-1 my-1 align-left text-body-2 text-wrap"
            close
            close-icon="mdi-close-circle"
            text-color="secondary"
            color="#f4f5f7"
            label
            @click:close="deleteProduct(index)">
            {{product.productName}}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" v-if="showInputs" class="d-flex justify-md-end">
          <v-text-field
            type="number"
            label="Cantidad"
            class="pr-3"
            ref="quantityInput"
            style="max-width: 200px;"
            :value="product.quantity"
            v-if="showCountOfProduct"
            @input="onInput($event, index, 'quantity')"
            outlined
            :rules="[rules.required]"
            dense
            v-osg-pattern=/^\d*\.?([0-9][0-9]?)?$/
            hide-details="auto"
          ></v-text-field>

          <v-text-field
            type="number"
            label="Precio unitario"
            style="max-width: 200px;"
            class="pl-3"
            @input="onInput($event, index, 'unitPrice')"
            v-if="showPriceOfProduct"
            :value="product.unitPrice"
            outlined
            ref="unitPriceInput"
            :rules="[rules.required]"
            :prefix="currencyCode"
            dense
            v-osg-pattern=/^\d*\.?([0-9][0-9]?)?$/
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row dense>
        <v-col cols="12">
          <slot name="no-data"></slot>
        </v-col>
      </v-row>
    </template>
    
  </v-container>
</template>

<script>
import MedicalCatalogService from '@/services/medical-catalogs.service';
import { camelizeKeys } from 'humps'
import { inputRules } from '@/utils'

export default {
  name: 'ProductFinder',
  props: {
    productTypeSlug: {
      type: String,
      default: 'laboratory',
    },
    searchLabel: {
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    countryCode: {
      type: String,
    },
    providerSlugs: {
      type: Array,
      default: () => [],
    },
    showCountOfProduct: {
      type: Boolean,
      default: false
    },
    showPriceOfProduct: {
      type: Boolean,
      default: false
    },
    currencyCode: {
      type: String
    }
  },
  data: () => ({
    searchInput: null,
    selectedProduct: null,
    products: [],
    loading: false,
    rules: inputRules
  }),
  methods: {
    selectProduct() {
      const productIndex = this.value.findIndex(
        (product) => product.id === this.selectedProduct.id
      );

      if (productIndex === -1) {
        this.$emit('input', [...this.value, this.selectedProduct]);
      }

      this.$refs.searchProductInput.reset();
      this.clearSearch();
      this.products = [];
    },
    deleteProduct(index) {
      this.$emit(
        'input',
        this.value.filter((product, i) => i !== index)
      );
    },
    clearSearch() {
      this.searchInput = null;
      this.selectedProduct = null;
    },
    validate() {
      this.validated = true;
      let validInputs = true;
      const fields = this.$refs;
       if (fields) {
       this.value.forEach((product, index) => {
          const priceValidation = fields['unitPriceInput'] && fields['unitPriceInput'][index].validate(true) || true;
          const quantityValidation = fields['quantityInput'] && fields['quantityInput'][index].validate(true) || true;
          if (validInputs) {
            validInputs = priceValidation && quantityValidation;
          }          
        })
      }
      return this.valid && validInputs
    },
    onInput(event, index, field) {
      const changeValues = [...this.value]
      const product = this.value[index]
      product[field] = event
      if (!product['currencyCode']) {
        product['currencyCode'] = this.currencyCode
      }
      changeValues[index] = product
      this.$emit('input', changeValues)
    }
  },
  computed: {
    searching() {
      return this.searchInput || this.selectedProduct;
    },
    valid() {
      if (!this.validated) return true;
      return this.value.length > 0;
    },
    showInputs() {
      return this.showCountOfProduct || this.showPriceOfProduct
    }
  },
  watch: {
    async searchInput(newValue) {
      if (newValue) {
        try {
          this.loading = true;
          const response = await MedicalCatalogService.getProducts({
            productTypeSlug: this.productTypeSlug,
            productName: newValue,
            countryCode: this.countryCode,
            providerSlugs: this.providerSlugs
          });
          this.products = camelizeKeys(response.data.content);
        } catch (error) {
          console.log(error)
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-chip ::v-deep .v-chip__close.v-icon { 
  color: var(--v-secondary-base);
  font-size: 1.25rem !important;
  right: 0;
}
</style>