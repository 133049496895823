<template>
  <v-dialog
    v-model="filePreview.showModal"
    :scrollable="isMobile"
    :persistent="!isMobile"
    :fullscreen="isMobile"
    :transition="isMobile ? 'dialog-bottom-transition' : ''"
    :max-width="$vuetify.breakpoint.smAndDown ? '350px' : '700px'"
  >
    <v-card>
      <v-card-title class="pl-8 pr-4 pt-8">
        <div class="d-flex align-center" style="width: 100%;height:48px;position: relative;">
          <span class="text-h4 secondary--text pl-2">{{ $t('authorizationDetail.information.preview.title') }}</span>
          <v-btn fab absolute right icon @click="close" height="24" width="24" >
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row style="width: 100%;" class="px-2 ma-auto">
          <v-col cols="12" class="py-0 text-wrap text-truncate" style="line-height: 16px;">
            <span class="text-subtitle-2"> {{ filePreview.file.name }}</span>
          </v-col>
          <v-col cols="12" class="py-0" style="line-height: 16px;">
            <span class="text-caption osg-gray--text"> {{ getType(filePreview.file.type) }}</span>
            <span class="osg-gray--text mx-2 text-caption" v-if="filePreview.file.size">&middot;</span>
            <span class="text-caption osg-gray--text" v-if="filePreview.file.size"> {{ getSize(filePreview.file.size) }}</span>
          </v-col>
        </v-row>
        <v-row style="width: 100%;" class="pt-3 ma-auto">
          <v-col class="pa-0 preview">
            <v-img class="img-preview" v-if="isImage()" contain 
              max-height="100%" 
              max-width="100%" :src="fileUrl"/>
            <div v-else style="width: 100%" class="grey lighten-2 preview d-flex justify-center align-center">
              <v-row>
                <v-col class="d-flex flex-column justify-center align-center">
                  <v-row>
                    <v-col>
                      <v-icon size="46" class="material-icons">{{ getIcon() }}</v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-1">
                      <span class="osg-gray--text text-body-2">
                        {{ $t('authorizationDetail.information.preview.label') }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="isDetailSource">
                    <v-col class="py-1">
                      <span style="cursor: pointer;" class="secondary--text text-button" @click="download">
                        {{ $t('authorizationDetail.information.preview.actions.download') }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-8 pt-0">
        <v-row>
          <v-col v-if="!isDetailSource" cols="6" class="text-start">
            <v-btn
              depressed
              color="grey lighten-4"
              class="rounded text-button error--text"
              @click="deleteFile"
            >
              {{ $t('authorizationDetail.information.preview.actions.delete') }}
            </v-btn>
          </v-col>
          <v-col :cols="isDetailSource ? 12 : 6" class="text-end">
            <v-btn
              depressed
              color="grey lighten-4"
              class="rounded text-button secondary--text"
              :class="{'mr-4': isDetailSource}"
              @click="close"
            >
              {{ $t('authorizationDetail.information.preview.actions.cancel') }}
            </v-btn>
            <v-btn
              v-if="isDetailSource"
              depressed
              color="primary"
              class="rounded text-button"
              @click="download"
            >
              {{ $t('authorizationDetail.information.preview.actions.download') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { imageExtensions } from '@/config/authorization'
import arcService from '@/services/arc-api.service'
import { getSizeDescription } from '@/utils'

export default {
  name: 'FilePreview',
  props: {
    filePreview: {
      type: Object,
      default: () => ({
        showModal: false,
        file: null,
        index: null,
        source: null
      })
    },
    addFilesOnDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileData: null,
      contentType: null
    }
  },
  async mounted() {
    if (this.isImage() && !this.addFilesOnDetail) {
      try {
        const response = await arcService.getAuthorizationFile(this.filePreview.file.fileId)
        this.fileData = response.data
        this.contentType = response.headers['content-type']
      } catch(error) { return }
    }
  },
  methods: {
    close() {
      this.filePreview.showModal = false;
    },
    isImage() {
      return imageExtensions.includes(this.filePreview.file.type) || 
      imageExtensions.includes(this.filePreview.file.type.split('/')[1]);
    },
    getIcon() {
      return this.filePreview.file.type === 'pdf' ? 'picture_as_pdf' : 'library_books';
    },
    async download() {
      try {
        if (!this.fileData || !this.addFilesOnDetail) {
          const response = await arcService.getAuthorizationFile(this.filePreview.file.id)
          this.fileData = response.data
          this.contentType = response.headers['content-type']
        }
        const url = window.URL.createObjectURL(new Blob([this.fileData], { type: this.contentType }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.filePreview.file.name)
        document.body.appendChild(link)
        link.click()
      } catch(error) { return }
    },
    getType(type) {
      return type.toUpperCase()
    },
    getSize(size) {
      return getSizeDescription(size)
    },
    deleteFile() {
      this.$emit('deleteFile', this.filePreview.file.fileId)
      this.close()
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    fileUrl() {
      const base64 = btoa(
        new Uint8Array(this.fileData)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
      return this.isDetailSource || !this.addFilesOnDetail ?  
        'data:'.concat(this.contentType,';base64,', base64) :
        URL.createObjectURL(this.filePreview.file) 
    },
    isDetailSource() {
      return this.filePreview.source === 'DETAIL'
    }
  } 
}
</script>

<style lang="scss" scoped>
.preview {
  height: 350px;
  border-radius: 8px;
  border: solid 1px #e0e0e0;
}

.img-preview {
  height: 350px;
  border-radius: 8px;
}
</style>