import Vue from 'vue';
import { config } from '@/plugins/env.config';
import { decamelizeKeys, camelizeKeys } from 'humps';

class ProductService {

    getProducts({ productTypeSlug, productName, countryCode, providerSlugs }) {
        const params = decamelizeKeys({ productTypeSlug, productName, countryCode, providerSlug: providerSlugs && providerSlugs.join(',') })

        return Vue.prototype.$http.get(`${config.apiBaseUrl}/medical-catalogs/v1/products/search`, { params });
    }

    formatProductFields({products}) {
        const camelizedProducts = camelizeKeys(products);
        if (Array.isArray(camelizedProducts)) {
            return camelizedProducts.map(product => {
                return {id: product.id, typeSlug: product.productTypeSlug}
            });
        }

        return [];
    }

    getIngredients({ name }) {
        const params = decamelizeKeys({ name })
        return Vue.prototype.$http.get(`${config.apiBaseUrl}/v1/ingredients`, { params } );
    }

    getDiagnoses({ name, tag }) {
        const params = decamelizeKeys({ name, tag })
        return Vue.prototype.$http.get(`${config.apiBaseUrl}/medical-catalogs/v1/diagnoses/search`, { params });
    }
}


export default new ProductService();