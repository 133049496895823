<template>
  <v-container fluid>
    <v-row v-if="Object.keys(authorization).length === 0">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          class="text-center"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else>
      <Claim></Claim>

      <CreditCardBalanceInvoice></CreditCardBalanceInvoice>

      <SettlementFiles></SettlementFiles>

      <OtherFiles></OtherFiles>

      <v-row v-for="group in groupKeys" :key="group + '1'">

        <v-col cols="12" v-if="getGroupInputs(groupFields[group]).length > 0">
          <v-row >
            <v-col>
              <span class="text-subtitle-1 secondary--text">
                {{ group }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" v-for="field in getGroupInputs(groupFields[group])" :key="field.id">
              <v-row no-gutters>
                <v-col>
                  <span class="text-overline-head secondary--text">
                    {{ field.fallbackLabel }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col v-if="field.fieldType === 'DIAGNOSES'">
                  <Diagnosis :diagnoses="JSON.parse(field.value)"></Diagnosis>
                </v-col>
                <v-col v-else-if="field.fieldType === 'MEDICAL_PROCEDURE_PRODUCT'">
                  <MedicalProcedures :medicalProcedures="JSON.parse(field.value)"></MedicalProcedures>
                </v-col>
                <v-col v-else-if="field.fieldType === 'DOCTOR'">
                  <Doctor :doctor="JSON.parse(field.value)"></Doctor>
                </v-col>
                <v-col v-else-if="field.fieldType === 'PRODUCT_INVOICE'">
                  <ProductInvoice :product="JSON.parse(field.value)"></ProductInvoice>
                </v-col>
                <v-col v-else-if="field.fieldType === 'DRUG_PRODUCT'">
                  <DrugProducts :products="JSON.parse(field.value)" :authorizationProducs="authorization.products"></DrugProducts>
                </v-col>
                 <v-col v-else-if="field.fieldType === 'BENEFICIARY'">
                  <Beneficiaries :beneficiaries="JSON.parse(field.value)"></Beneficiaries>
                </v-col>
                <v-col v-else-if="field.fieldType === 'DECIMAL'">
                  <span class="text-caption">
                    {{ field.prefix }} {{ formattedAmount(field.value, 2) }}
                  </span>
                </v-col>
                <v-col v-else-if="field.fieldType === 'DATE'">
                  <span class="text-caption">
                    {{ field.prefix }} {{ formattedDate(field.value) }}
                  </span>
                </v-col>
                <v-col v-else-if="field.fieldType === 'DROPDOWN' ">
                  <span class="text-caption" v-if="field.fieldSlug == 'sum_assured' || field.fieldSlug == 'requested_reimbursement_amount'">
                    {{ formatAmountFromString(field.value) }}
                  </span>
                  <span class="text-caption" v-else>
                    {{ field.prefix }} {{ field.value }}
                  </span>
                </v-col>
                <v-col v-else>
                  <span class="text-caption">
                    {{ field.prefix }} {{ field.value }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="getGroupFilesByGroupField(groupFields[group]).length > 0">
          <v-row >
            <v-col>
              <span class="text-h5 secondary--text">
                {{ group }}
              </span>
            </v-col>
          </v-row>
          <div v-for="(groupField, index) in getGroupFilesByGroupField(groupFields[group])" :key="groupField + index">
            <v-row>
              <v-col class="pl-4 mt-3">
                <span class="text-subtitle-1 primary--text">
                  {{ groupField }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="12" v-if="getGroupFilesWithGroupField(filesByGroupField[groupField]).length > 0">
                <div v-for="field in getGroupFilesWithGroupField(filesByGroupField[groupField])" :key="field.id">
                  <v-row>
                    <v-col>
                      <span class="text-subtitle-2 pl-3 secondary--text">
                        {{ field }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-2">
                    <v-col class="grey lighten-4">
                      <v-row class="ma-auto" no-gutters>
                        <v-col cols="12">
                          <DisplayFiles :files="getFilesWithGroupField(field, groupField)"
                            source="DETAIL"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" v-if="getGroupFilesWithOutGroupField(groupFields[group]).length > 0">
          <v-row >
            <v-col>
              <span class="text-h8 secondary--text">
                {{ group }}
              </span>
            </v-col>
          </v-row>
          <div v-for="field in getGroupFilesWithOutGroupField(groupFields[group])" :key="field.id">
            <v-row>
              <v-col>
                <span class="text-subtitle-2 secondary--text">
                  {{ field }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col class="grey lighten-4">
                <v-row class="ma-auto" no-gutters>
                  <v-col cols="12">
                    <DisplayFiles :files="getFiles(field)"
                      source="DETAIL"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>

      </v-row>
    </div>
  </v-container>
</template>

<script>
import Diagnosis from './detail-fields/Diagnosis.vue'
import MedicalProcedures from './detail-fields/MedicalProcedures.vue'
import Doctor from './detail-fields/Doctor.vue'
import ProductInvoice from './detail-fields/ProductInvoice.vue'
import DrugProducts from './detail-fields/DrugProducts.vue'
import Beneficiaries from './detail-fields/Beneficiaries.vue'
import Claim from './Claim.vue'
import CreditCardBalanceInvoice from './CreditCardBalanceInvoice.vue'
import SettlementFiles from './SettlementFiles.vue'
import OtherFiles from './OtherFiles.vue'
import DisplayFiles from '../../files/DisplayFiles.vue'
import { createNamespacedHelpers } from 'vuex'
import { groupBy } from '@/utils'
import moment from 'moment'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'Information',
  components: { Diagnosis, DisplayFiles, Doctor, ProductInvoice, DrugProducts, Beneficiaries,
    Claim, CreditCardBalanceInvoice, SettlementFiles, OtherFiles, MedicalProcedures },
  methods: {
    getFiles(fileName) {
      return this.authorization.files.filter(file => file.fallbackLabel === fileName)
        .map(file => ({
          ...file,
          name: file.originalFileName,
          type: file.extension
        }))
    },
    getFilesWithGroupField(fileName, groupField) {
      return this.authorization.files.filter(file => file.fallbackLabel === fileName && file.groupField === groupField)
        .map(file => ({
          ...file,
          name: file.originalFileName,
          type: file.extension
        }))
    },
    getFilesByGroupField(groupName) {
      return this.authorization.files.filter(file => file.groupField === groupName)
        .map(file => ({
          ...file,
          name: file.originalFileName,
          type: file.extension
        }))
    },
    getGroupInputs(items) {
      return items.filter(input => !input.extension).sort((a, b) => {
        if (a.showOrder > b.showOrder) return 1;
        if (b.showOrder > a.showOrder) return -1;
        return 0;
      })
    },
    getGroupFilesWithOutGroupField(items) {
      const files = items.filter(input => input.extension && input.groupField === null)

      const groupByFieldName = groupBy(files, 'fallbackLabel')

      return Object.keys(groupByFieldName)
    },
    getGroupFilesWithGroupField(items) {
      const files = items.filter(input => input.extension && input.groupField !== null)

      const groupByFieldName = groupBy(files, 'fallbackLabel')

      return Object.keys(groupByFieldName)
    },
    getGroupFilesByGroupField(items) {
      const files = items.filter(input => input.extension && input.groupField !== null)

      const groupByFieldName = groupBy(files, 'groupField')

      return Object.keys(groupByFieldName)
    },
    formattedAmount(amount, precission) {
      var regex = /MXN\$\s?/;

      if (regex.test(amount)) {
        return amount;
      }

      let userLocale;
      const parsedAmount = parseFloat(amount.toString().replace(/,/g, ''));
      const currentLocale = this.$i18n.locale;
      const countryCode = this.countryCode || '';
      const currentLocaleIsCustom = currentLocale && currentLocale.includes("-");


      if (currentLocaleIsCustom) {
        userLocale = currentLocale;
      } else if (currentLocale) {
        userLocale = `${currentLocale}-${countryCode}`.replace(/-$/, '');
      }

      let isValidLocale = false;
      if (userLocale) {
        try {
          isValidLocale = Intl.DateTimeFormat.supportedLocalesOf(userLocale).length > 0;
        } catch (error) {
          console.warn(`Error validating locale: ${error.message}`);
        }
      }

      if (!isValidLocale) {
        console.warn(`Invalid user locale: ${userLocale}.`);
        userLocale = undefined;
      }

      return isNaN(parsedAmount)
        ? '0.00'
        : parsedAmount.toLocaleString(userLocale, {
            minimumFractionDigits: precission,
            maximumFractionDigits: precission
          });
    },
    formattedDate(value) {
      let parsedDate = value;
      if (moment(value, moment.ISO_8601).isValid()) {
        parsedDate = moment(value).format("DD/MM/YYYY HH:mm")
      }

      return parsedDate

    },
    formatAmountFromString(amountWithCurrency) {
      const currencyFromString = amountWithCurrency.match(/MXN\$/);
      const amount = amountWithCurrency.replace(/MXN\$ /, '');
      return currencyFromString[0] + ' ' + this.formattedAmount(amount, 2);
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'countryCode']),
    groupFields() {
      let groups = []
      if (this.authorization.inputs && this.authorization.files) {
        const fields = [...this.authorization.inputs,
          ...this.authorization.files
          ].sort((a, b) => {
            if (a.showOrder > b.showOrder) return 1;
            if (b.showOrder > a.showOrder) return -1;
            return 0;
          })

        groups =
          groupBy(
            fields,
          'groupName'
          )
      }

      return groups;
    },
    filesByGroupField() {
      let groups = []
      if (this.authorization.inputs && this.authorization.files) {
        const fields = [...this.authorization.inputs,
          ...this.authorization.files
          ].sort((a, b) => {
            if (a.showOrder > b.showOrder) return 1;
            if (b.showOrder > a.showOrder) return -1;
            return 0;
          })

        groups =
          groupBy(
            fields,
          'groupField'
          )
      }

      return groups;
    },
    groupKeys() {
      return Object.keys(this.groupFields);
    },
   }
}
</script>
