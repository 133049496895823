<template>
  <v-container class="px-0">
    <v-row no-gutters>
      <v-col>
        <span class="text-caption">{{ doctor.name }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span class="text-caption">{{ doctor.specialty }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span class="text-caption">
          {{ $t('addAuthorization.customObjectFields.doctor.medicalLicense') }}: 
          {{ doctor.medicalLicense }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
export default {
  name: 'Doctor',
  props: {
    doctor: {
      type: Object,
      default: () => {}
    }
  }
}
</script>