<template>
  <v-container class="px-0" v-if="showPrescriptionSection"> 
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="my-1"> 
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ $t('authorizationDetail.information.prescription.label') }}
            </span>
          </v-col>
        </v-row>
        <v-row class="my-2" no-gutters v-if="diagnoses && diagnoses.length > 0">
          <v-col>
            <v-row no-gutters>
              <v-col>
                <span class="text-subtitle-2 osg-gray--text">
                  {{ $t('authorizationDetail.information.prescription.diagnosesTitle') }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters v-for="(diagnosis, index) in diagnoses" :key="index">
              <v-col>
                <v-row no-gutters>
                  <v-col cols="8" md="10">
                    <span class="text-caption osg-black--text">
                      {{ getDiagnosisCode(diagnosis.code) }} {{ diagnosis.name }}
                    </span>
                  </v-col>
                  <v-col cols="4" md="2" class="text-right">
                    <span 
                      :class="['text-button',
                        {'primary--text': isApproved(diagnosis.status),
                        'error--text': !isApproved(diagnosis.status)}]" >
                      {{ $t(getDiagnosisStatus(diagnosis.status)) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="diagnosis.reason">
                  <v-col cols="8" md="10" style="line-height: 1.2">
                    <span class="text-caption osg-gray--text">
                      {{ diagnosis.reason }}
                    </span>
                  </v-col>
                </v-row>
                <v-divider v-if="index !== diagnoses.length - 1" class="my-2" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2" v-if="prescriptionFiles && prescriptionFiles.length > 0">
          <v-col class="grey lighten-4">
            <v-row class="ma-auto" no-gutters>
              <v-col cols="12">
                <DisplayFiles :files="prescriptionFiles" 
                  source="DETAIL"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DisplayFiles from '../../files/DisplayFiles'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'Prescription',
  components: { DisplayFiles },
  methods: {
    getDiagnosisStatus(status) {
      const statusLabel = 'authorizationDetail.information.prescription.diagnosisStatus'.concat(
        status === 'APPROVED' ? '.approved' : '.rejected'
      )
      return statusLabel
    },
    isApproved(status) {
      return status === 'APPROVED'
    },
    getDiagnosisCode(code) {
      return code ? '('.concat(code, ')') : ''
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    diagnoses() {
      return this.authorization.diagnoses
    },
    prescriptionFiles() {
      return this.authorization.files && this.authorization.files
      .filter(file => file.fieldSlug.toUpperCase().includes('PRESCRIPTION'))
      .map(file => { return {
        ...file,
        name: file.originalFileName,
        type: file.extension,
      }})
    },
    showPrescriptionSection() {
      return (this.diagnoses && this.diagnoses.length > 0 || this.prescriptionFiles && this.prescriptionFiles.length > 0)
    },
  }
}
</script>