<template>
  <v-container fluid>
    <Claim></Claim>
        
    <CreditCardBalanceInvoice></CreditCardBalanceInvoice>
    
    <Prescription></Prescription>

    <Result></Result>

    <v-row v-for="group in groupKeys" :key="group + '1'">
      <v-col cols="12" v-if="getGroupInputs(groupFields[group]).length > 0">
        <v-row >
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ group }}
            </span>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12" md="6" v-for="field in getGroupInputs(groupFields[group])" :key="field.id">
            <v-row no-gutters>
              <v-col>
                <span class="text-overline-head secondary--text">
                  {{ field.fallbackLabel }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <span class="text-caption">
                  {{ field.prefix }} {{ field.value }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <SettlementFiles></SettlementFiles>
    
    <OtherFiles></OtherFiles>

    <v-row v-for="(group, index) in beneficiaryFields" :key="index">
      <v-col cols="12">
        <v-row>
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ group }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-for="field in getBeneficiaryGroupFields(group)" :key="field.id">
          <v-row no-gutters>
            <v-col>
              <span class="text-overline-head secondary--text">
                {{ getBeneficiaryFieldTitle(field) }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <span class="text-caption">
                {{ field.prefix }} {{ field.value }} {{ field.suffix }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-for="group in groupKeys" :key="group + '2'">
      <v-col cols="12" v-if="getGroupFiles(groupFields[group]).length > 0">
        <v-row >
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ group }}
            </span>
          </v-col>
        </v-row>
        <div v-for="field in getGroupFiles(groupFields[group])" :key="field.id">
          <v-row>
            <v-col>
              <span class="text-subtitle-1 secondary--text">
                {{ field }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col class="grey lighten-4">
              <v-row class="ma-auto" no-gutters>
                <v-col cols="12">
                  <DisplayFiles :files="getFiles(field)" 
                    source="DETAIL"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Claim from './section/Claim'
import CreditCardBalanceInvoice from './section/CreditCardBalanceInvoice'
import Prescription from './section/Prescription'
import OtherFiles from './section/OtherFiles'
import Result from './section/Result'
import SettlementFiles from './section/SettlementFiles.vue'
import { groupBy } from '@/utils/forms.utils'
import DisplayFiles from '../files/DisplayFiles'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'CreditCardBalanceInformation',
  components: { Claim, CreditCardBalanceInvoice, Prescription, OtherFiles, Result, DisplayFiles, SettlementFiles },
  methods: {
    getFiles(fileName) {
      return this.authorization.files.filter(file => file.fallbackLabel === fileName)
        .map(file => ({
          ...file,
          name: file.originalFileName,
          type: file.extension
        }))
    },
    getGroupInputs(items) {
      return items.filter(input => !input.extension).sort((a, b) => {
        if (a.showOrder > b.showOrder) return 1;
        if (b.showOrder > a.showOrder) return -1;
        return 0;
      })
    },
    getGroupFiles(items) {
      const files = items.filter(input => input.extension)

      const groupByFieldName = groupBy(files, 'fallbackLabel')

      return Object.keys(groupByFieldName)
    },
    getBeneficiaryFieldTitle(field) {
      return field.fieldSlug.toLowerCase().includes('id') ? 
        this.$t('authorizationDetail.information.beneficiary.identification') :
          field.fieldSlug.toLowerCase().includes('percentage') ? 
            this.$t('authorizationDetail.information.beneficiary.percentage') :
            this.$t('authorizationDetail.information.beneficiary.name')
    },
    getBeneficiaryGroupFields(group) {
      return this.beneficiaryGroupFields[group]
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    groupFields() {
      let groups = []
      if (this.authorization.inputs && this.authorization.files) {
        const fields = [...this.authorization.inputs, 
          ...this.authorization.files
            .filter(file => !file.fieldSlug.toUpperCase().includes('OTHER'))
          ].sort((a, b) => {
            if (a.showOrder > b.showOrder) return 1;
            if (b.showOrder > a.showOrder) return -1;
            return 0;
          })

        groups = 
          groupBy(
            fields.filter(field => field.extension || !field.fieldSlug.toLowerCase().includes('beneficiary')),
          'groupName'
          )
      }

      return groups;
    },
    groupKeys() {
      return Object.keys(this.groupFields);
    },
    beneficiaryGroupFields() {
      const fields = this.authorization.inputs
        .filter(field => field.fieldSlug.toLowerCase().includes('beneficiary'))
        .sort((a, b) => {
          if (a.showOrder > b.showOrder) return 1;
          if (b.showOrder > a.showOrder) return -1;
          return 0;
        })

      return groupBy(fields, 'groupField')
    },
    beneficiaryFields() {
      return Object.keys(this.beneficiaryGroupFields)
    }
  }
}
</script>