<template>
  <v-container class="px-0">
    <v-row no-gutters>
      <v-col>
        <span class="text-overline-head grey--text text--darken-1 pr-2"> 
          {{ $t('addAuthorization.customObjectFields.productInvoice.taxIdentificationNumber') }}: 
        </span>
        <span class="text-caption"> {{ product.taxIdentificationNumber }} </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span class="text-overline-head grey--text text--darken-1 pr-2"> 
          {{ $t('addAuthorization.customObjectFields.productInvoice.providerName') }}: 
        </span>
        <span class="text-caption"> {{ product.providerName }} </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span class="text-overline-head grey--text text--darken-1 pr-2"> 
          {{ $t('addAuthorization.customObjectFields.productInvoice.invoiceNumber') }}: 
        </span>
        <span class="text-caption"> {{ product.invoiceNumber }} </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span class="text-overline-head grey--text text--darken-1 pr-2"> 
          {{ $t('addAuthorization.customObjectFields.productInvoice.invoiceDate') }}: 
        </span>
        <span class="text-caption"> {{ product.invoiceDate }} </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProductInvoice',
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  }
}
</script>