<template>
  <v-container class="px-0">
    <v-row class="py-2" no-gutters v-for="(item, index) in beneficiaries" :key="index">
      <v-col cols="12">
        <span class="text-overline-head grey--text text--darken-1 pr-2">{{ $t('addAuthorization.customObjectFields.beneficiary.name') }}</span>
        <span class="text-caption">{{ item.name }}</span>
      </v-col>
      <v-col cols="12">
        <span class="text-overline-head grey--text text--darken-1 pr-2">{{ $t('addAuthorization.customObjectFields.beneficiary.phone') }}</span>
        <span class="text-caption">{{ item.phone }}</span>
      </v-col>
      <v-col cols="12">
        <span class="text-overline-head grey--text text--darken-1 pr-2">{{ $t('addAuthorization.customObjectFields.beneficiary.email') }}</span>
        <span class="text-caption">{{ item.email }}</span>
      </v-col>
      <v-col cols="12">
        <span class="text-overline-head grey--text text--darken-1 pr-2">{{ $t('addAuthorization.customObjectFields.beneficiary.percentage') }}</span>
        <span class="text-caption">{{ item.percentage }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Beneficiaries',
  props: {
    beneficiaries: {
      type: Array,
      default: () => []
    }
  }
}
</script>