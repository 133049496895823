<template>
  <v-container fluid>
    <v-row v-if="!listAuthorizationSelected">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          class="text-center"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="pb-0">
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 1" :class="[$vuetify.breakpoint.mdAndUp ? 'd-flex justify-right pl-8': 'mr-4']">
            <v-btn
              v-if="$vuetify.breakpoint.mdAndUp"
              depressed
              large
              class="text-body-2"
              height="32"
              @click="onBack"
            >
              <v-icon size="24" color="primary">navigate_before</v-icon>
              <span class="text-button primary--text">
                {{ $t('authorizationDetail.buttons.exit') }}
              </span>
            </v-btn>
            <v-btn
              v-else
              icon
              @click="onBack"
            >
              <v-icon size="24" color="primary">navigate_before</v-icon>
            </v-btn>                         
          </v-col>
          <v-col cols="10" class="pt-2 d-flex">
            <span style="line-height: 1.2;" :class="['text-h4 secondary--text mr-2', { 'font-size-1-3' : !$vuetify.breakpoint.mdAndUp }]">
              {{ $t('authorizationDetail.idTitleLabel') }} 
              {{ authorization.authorizationCode }} 
            </span>
            <v-chip small label
              v-if="$vuetify.breakpoint.mdAndUp"  
              :text-color="authorization.statusConfiguration.textColor" 
              :color="authorization.statusConfiguration.color" 
              class="text-overline-head text-wrap ml-6"
            >
              {{ $t(authorization.statusLabel) }}
              <v-icon size="16" v-if="showIcon(authorization.status)"
                :color="authorization.iconConfiguration.color" right>
                {{authorization.iconConfiguration.icon}}
              </v-icon>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>      
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" :class="['pt-0', { 'ma-auto': $vuetify.breakpoint.mdAndUp}]">
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters class="mb-3">
              <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="1" class="mr-4"></v-col>
              <v-col cols="auto">
                <span :class="['text-subtitle-1 osg-gray--text mr-4', {'font-size-0-75' : !$vuetify.breakpoint.mdAndUp}]"> {{ $t(authorization.type) }} </span>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.mdAndUp">
                <v-chip small label
                  :text-color="authorization.statusConfiguration.textColor" 
                  :color="authorization.statusConfiguration.color" 
                  class="text-overline-head text-wrap"
                >
                  {{ $t(authorization.statusLabel) }}
                  <v-icon size="16" v-if="showIcon(authorization.status)"
                    :color="authorization.iconConfiguration.color" right>
                    {{authorization.iconConfiguration.icon}}
                  </v-icon>
                </v-chip> 
              </v-col>
            </v-row>           
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <span class="text-caption osg-gray--text">
              {{ $t('authorizationDetail.updatedDateLabel', [ lastUpdate ]) }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-caption" style="color: #4f4f4f;">
              {{ $t('authorizationDetail.message') }}
            </span>
          </v-col>
        </v-row>        
        <v-row>
          <v-col>
            <v-tabs v-model="tab" centered grow>
              <v-tab v-for="item in items" :key="item.component"
                class="text-button">
                {{ $t(item.tab) }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item.component">
                <component :is="item.component" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import Information from './Information'
import Observations from './Observations'
import { tabsDetail } from '@/config/authorization'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'AuthorizationDetail',
  components: {
    Information,
    Observations
  },
  data() {
    return {
      tab: null
    }
  },
  filters: {
    dateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    }
  },
  methods: {
    ...authorizationModule.mapActions(['setSingleAuthorization']),
    showIcon(status) {
      return status === 'CLAIMED' || status === 'READY'
    },
    onBack() {
      this.setSingleAuthorization({})
      this.$router.push('/home')
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    items() {
      return tabsDetail
    },
    listAuthorizationSelected() {
      return Object.keys(this.authorization).length > 0
    },
    lastUpdate() {
      return this.$options.filters.dateTime(this.authorization.updatedAt);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
.font-size-1-3{
  font-size: 1.3rem !important;
}
.font-size-0-75 {
  font-size: 0.75rem !important;
}
</style>