<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="11">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              hide-details="auto"
              :label="$t('addAuthorization.customObjectFields.beneficiary.name')"
              outlined
              ref="name"
              :rules="[rules.required]"
              @input="onInput($event, 'name')"
              :value="value.name"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              hide-details="auto"
              :label="$t('addAuthorization.customObjectFields.beneficiary.phone')"
              outlined
              type="number"
              ref="phone"
              :rules="[rules.required]"
              @input="onInput($event, 'phone')"
              :value="value.phone"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              hide-details="auto"
              :label="$t('addAuthorization.customObjectFields.beneficiary.email')"
              outlined
              :rules="emailRulesBySponsor()"
              ref="email"
              @input="onInput($event, 'email')"
              :value="value.email"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              hide-details="auto"
              :label="$t('addAuthorization.customObjectFields.beneficiary.percentage')"
              outlined
              type="number"
              ref="percentage"
              @input="onInput($event, 'percentage')"
              v-osg-pattern=/^[0-9]+$/
              :rules="[rules.zero, rules.required, rules.validatePercentage(globalPercentage), rules.validateTotalPercentage(globalPercentage)]"
              :value="value.percentage"
              suffix="%"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex align-center justify-center">
        <v-btn
          icon
          v-if="index > 0"
          color="error"
          class="text-body-2 white"
          height="20"
          width="20"
          @click="onDelete"
        >
          <v-icon size="20">delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { inputRules } from "@/utils";
  import { createNamespacedHelpers } from 'vuex'
  const authorizationModule = createNamespacedHelpers('authorization');

  export default {
    name: "Beneficiary",
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      globalPercentage: {
        type: Number,
        default: () => 0
      },
      index: {
        type: Number,
        default: () => 0
      }
    },
    data: () => ({
      rules: inputRules,
    }),
    methods: {
      onDelete() {
        this.$emit('onDelete')
      },
      onInput(inputValue, field) {
        const changeValue = this.value;
        changeValue[field] = inputValue
        this.$emit('input', changeValue)
      },
      validate() {
        return this.$refs.name.validate(true) && this.$refs.phone.validate(true) && this.$refs.email.validate(true) && this.$refs.percentage.validate(true)
      },
      emailRulesBySponsor(){
        if(this.sponsorSlug === 'mx-contigo'){
          return [this.rules.email]
        }
        return [this.rules.required, this.rules.email]
      }
    },
    computed: {
      ...authorizationModule.mapGetters(['sponsorSlug'])
    }
  }
</script>