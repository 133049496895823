<template>
  <v-container class="px-0" v-if="claim && claim.payment">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="my-1">
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ $t('authorizationDetail.information.payment.label') }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters class="mt-2" v-if="!!claim.payment.documentNumber">
              <v-col class="text-body-2 osg-black--text">
                <span>
                  {{ $t('authorizationDetail.information.payment.documentLabel') }}
                </span>
                <span>
                  {{ claim.payment.documentNumber }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!!claim.payment.recipientBankAccountNumber">
              <v-col class="text-caption osg-gray--text">
                <span>
                  {{ $t('authorizationDetail.information.payment.accountLabel') }}
                </span>
                <span>
                  {{ claim.payment.recipientBankAccountNumber }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!!claim.payment.bankName">
              <v-col class="text-caption osg-gray--text">
                <span>
                  {{ $t('authorizationDetail.information.payment.bankLabel') }}
                </span>
                <span>
                  {{ claim.payment.bankName }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!!claim.payment.paymentDate">
              <v-col class="text-caption osg-gray--text">
                <span>
                  {{ $t('authorizationDetail.information.payment.paymentDate') }}
                </span>
                <span>
                  {{ claim.payment.paymentDate | dateTime }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'Claim',
  filters: {
    dateTime(date) {
      return moment(date).utc().format('DD/MM/YYYY')
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    claim() {
      let claim = null;
      if (this.authorization.isReimbursement && this.authorization.claims.length > 0) {
        claim = this.authorization.claims[0]
      }
      return claim
    },
  }
}
</script>