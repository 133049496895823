<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{ field.label }}</span>
      </v-col>
      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }" >
        <span class="text-overline" v-if="!field.required && field.label"> {{ $t('addAuthorization.fields.optional') }} </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{ field.description }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field ref="fieldValidationForm" 
          :rules="[...fieldRules, (value) => rules.maxLength(value, field.max), (value) => rules.minLength(value, field.min)]" 
          type="number" outlined dense
          :prefix="field.prefix" 
          :suffix="field.suffix" 
          v-osg-pattern=/^[0-9]+$/ 
          v-model="fieldValue" 
          @input="changeInput"
          hide-details="auto" 
          :placeholder="field.placeholder" 
          :hint="field.helpText" 
          persistent-hint>
        </v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {
  inputRules
} from '@/utils'
export default {
  name: 'PhoneField',
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: 'col-12',
        min: null,
        max: null,
        defaultValue: null
      })
    },
  },
  data() {
    return {
      rules: inputRules,
      fieldValue: null,
    }
  },
  methods: {
    validate() {
      if (!this.$refs.fieldValidationForm.validate(true)) {
        return false;
      }
      return true;
    },
    changeInput(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    fieldRules() {
      const fieldRulesArray = []
      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }
      return fieldRulesArray
    }
  }
}
</script>