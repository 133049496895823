<template>
  <AddFileModalContent :showModal="showModal">

    <template v-slot:alert="{ isMobile }">
      <v-alert
        text
        dismissible
        prominent
        transition="scale-transition"
        dense
        :class="['grow', isMobile ? 'mb-0' :'mb-0']"
        border="left"
        colored-border
        color="error"
        v-model="alert"
      >
        <v-row dense align="center">
          <v-col class="pa-0 shrink">
            <v-btn icon color="error">
              <v-icon class="material-icons">warning</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-body-2 pa-0 grow">{{ getError.error }}</v-col>
        </v-row>
      </v-alert>
    </template>

    <template v-slot:mobile-header>
      <v-toolbar dark color="primary" max-height="56px" class="mb-2">
        <v-btn icon dark @click.stop="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('authorizationDetail.observations.addOtherFiles.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :disabled="disabledButton" :loading="authorizationLoading" class="pa-0" dark text @click="submitForm">{{ $t('authorizationDetail.observations.addOtherFiles.uploadButton') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <template v-slot:default-header>
      <span class="text-h5 secondary--text">{{ $t('authorizationDetail.observations.addOtherFiles.addFilesTitle') }}</span>
    </template>

    <template v-slot:content>
      <AuthorizationField :field="field" :showPreview="false" :addFilesOnDetail="true">
        <template v-slot:title>
          <span class="text-h4 secondary--text">{{ field.label }}</span>
        </template>
      </AuthorizationField>
    </template>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn
            class="secondary--text mr-2"
            rounded
            depressed
            small
            @click="cancel"
          >{{ $t('authorizationDetail.observations.addOtherFiles.cancelButton') }}</v-btn>
          <v-btn
            color="primary"
            rounded
            depressed
            primary
            small
            :disabled="disabledButton"
            :loading="loading"
            @click="submitForm"
          >{{ $t('authorizationDetail.observations.addOtherFiles.uploadButton') }}</v-btn>
        </v-col>
      </v-row>
    </template>
  </AddFileModalContent>
</template>

<script>
import AddFileModalContent from './AddFileModalContent';
import AuthorizationField from '../add-authorization/AuthorizationField';
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'AddFileModal',
  components: { AddFileModalContent, AuthorizationField },
  props: {
    field: {
      type: Object
    }
  },
  data: () => ({
    error: '',
    showModal: false,
    loading: false
  }),
  computed: {
    ...authorizationModule.mapGetters(['getError', 'files']),
    alert: {
      get() {
        return this.getError.hasError;
      },
      set(value) {
        this.setError({hasError: value, error: null})
      }
    },
     disabledButton() {
      const field = this.files.find(file => file.fieldSlug === this.field.slug)
      return field && field.files ? field.files.length === 0 : true
    }
  },
  methods: {
    ...authorizationModule.mapActions(['sendReimbursementFilesWithComment', 'setError', 'resetAuthorizationState']),
    cancel() {
      this.error = null;
      this.resetError()
      this.resetAuthorizationState();
      this.showModal = false;
    },
    onShowModal() {
      this.showModal = true;
    },
    async submitForm() {
      try {
        this.loading = true
        await this.sendReimbursementFilesWithComment()
        this.showModal = false;
        this.loading = false
        location.reload();
      } catch(error) {
        const { response } = error;
        this.setError({hasError: true, error: response && response.data.message })
        this.loading = false
      }
    },
    resetError() {
      this.setError({hasError: false, error: null})
    }
  }
}
</script>