<template>
  <v-dialog
    v-model="showModal"
    :scrollable="isMobile"
    :persistent="!isMobile"
    :hide-overlay="isMobile"
    :transition="isMobile ? 'dialog-bottom-transition' : ''"
    :fullscreen="isMobile"
    max-width="700px"
  >
    <v-card :class="{'pa-6': !isMobile}">
      <v-row dense class="mx-6 mb-0" v-if="!isMobile">
        <v-col cols="12">
          <slot name="alert" :is-mobile="isMobile"></slot>
        </v-col>
      </v-row>
      
      <v-card-text>
        <slot name="content" :is-mobile="isMobile"></slot>
      </v-card-text>
      <v-card-actions v-if="!isMobile" class="pa-0 pr-6">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddFileModalContent',
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>