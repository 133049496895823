<template>
  <v-container class="px-0" v-if="showOtherFilesSection">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="my-1"> 
          <v-col cols="8" lg="10">
            <v-row no-gutters>
              <span class="text-subtitle-1 secondary--text">
                {{ $t('authorizationDetail.information.otherFiles.title') }}
              </span>
            </v-row>
            <v-row no-gutters>
              <span class="text-body-2" style="color: #4f4f4f;">
                {{ $t('authorizationDetail.information.otherFiles.label') }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="4" lg="2" class="d-flex justify-end align-center">
            <v-btn
              depressed
              small
              v-if="showAttachOtherFilesButton"
              rounded
              @click="onAddFiles"
            >
              <v-icon small color="secondary">mdi-plus</v-icon>
              <span class="text-button secondary--text">
                {{ $t('authorizationDetail.information.otherFiles.addFilesButton') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-2" v-if="otherFiles && otherFiles.length > 0">
          <v-col class="grey lighten-4">
            <v-row class="ma-auto" no-gutters>
              <v-col cols="12">
                <DisplayFiles :files="otherFiles" source="DETAIL"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <AddFileModal ref="addFileModal" :field="otherFilesField"></AddFileModal>
    </v-row>
  </v-container>
</template>

<script>
import DisplayFiles from '../../files/DisplayFiles'
import AddFileModal from '../../files/AddFileModal'
import { disableAdditionalFilesButton } from '@/config/authorization'

import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'OtherFiles',
  components: { DisplayFiles, AddFileModal },
  methods: {
    ...authorizationModule.mapActions(['setFiles', 'setError']),
    onAddFiles() {
      this.setFiles([])
      this.setError({hasError: false, error: null})
      this.$refs.addFileModal && this.$refs.addFileModal.onShowModal();
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'authorizationForms']),
    otherFiles() {
      return this.authorization.files && this.authorization.files
      .filter(file => file.fieldSlug.toUpperCase().includes('OTHER'))
      .map(file => ({...file, name: file.originalFileName, type: file.extension,}))
    },
    otherFilesField() {
      const coverageType = this.authorization.coverageType;
      const coverageForm = this.authorizationForms.find(form => form.authorizationCoverage === coverageType)
      if (coverageForm) {
        return coverageForm.fields.find(field => field.slug.toUpperCase().includes('OTHER') && field.type === 'FILE_UPLOAD')
      }
      return null;
    },
    showAttachOtherFilesButton() {
      return disableAdditionalFilesButton 
        && !disableAdditionalFilesButton.includes(this.authorization.status) 
        && this.otherFilesField
    },
    showOtherFilesSection() {
      return (this.otherFiles && this.otherFiles.length > 0) || this.otherFilesField
    },
  }
}
</script>