<template>
  <v-container>
    <v-row>
      <v-col cols="4" v-for="field in fields" :key="field.slug">
        <v-text-field
          v-if="field.type === 'DATE'"
          append-outer-icon="event"
          v-osg-date
          :label="$t(field.label)"
          outlined
          dense
          :ref="field.slug"
          type = "text"
          inputmode = "numeric"
          hide-details="auto"
          :rules="[...fieldRules(field), rules.date]"
          @input="onInput($event, field.slug)"
          :value="value[field.slug]"
        ></v-text-field>

        <v-text-field
          v-else
          hide-details="auto"
          :label="$t(field.label)"
          outlined
          :ref="field.slug"
          :rules="[...fieldRules(field)]"
          @input="onInput($event, field.slug)"
          :value="value[field.slug]"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import { inputRules } from '@/utils'
export default {
  name: 'ObjectField',
  props: {
    type: {
      type: String
    },
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    countryCode: {
      type: String,
    }
  },
  data: () => ({
    rules: inputRules
  }),
  methods: {
    onInput(inputValue, field) {
      const changeValue = this.value
      changeValue[field] = inputValue
      if (this.type === 'DOCTOR') {
        changeValue['countryCode'] = this.countryCode
      }
      this.$emit('input', changeValue)
    },
    validate() {
      const hasValues = Object.keys(this.value).length > 0
      let validInputs = true;
      const fields = this.$refs;
      if (fields) {
        this.fields.forEach((field) => {
          const validInput = fields[field.slug][0].validate(true);
          if (validInputs) {
            validInputs = validInput
          }
        })
      }
      return hasValues && validInputs
    },
    fieldRules(field) {
      const fieldRulesArray = []
      if (field.required) {
        fieldRulesArray.push(this.rules.required)
      }
      return fieldRulesArray
    }
  },
}
</script>