<template>
  <v-container class="px-0" v-if="showResultFilesSection">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="my-1"> 
          <v-col>
            <span class="text-subtitle-1 secondary--text">
              {{ $t('authorizationDetail.information.resultFiles.label') }}
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-2" v-if="resultFiles && resultFiles.length > 0">
          <v-col class="grey lighten-4">
            <v-row class="ma-auto" no-gutters>
              <v-col cols="12">
                <DisplayFiles :files="resultFiles" source="DETAIL"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DisplayFiles from '../../files/DisplayFiles'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: 'Result',
  components: { DisplayFiles },
  computed: {
    ...authorizationModule.mapGetters(['authorization']),
    resultFiles() {
      return this.authorization.files && this.authorization.files
      .filter(file => file.fieldSlug.toUpperCase().includes('RESULTS'))
      .map(file => { return {
        ...file,
        name: file.originalFileName,
        type: file.extension,
      }})
    },
    showResultFilesSection() {
      return this.resultFiles && this.resultFiles.length > 0
    }
  }
}
</script>