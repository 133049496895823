<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{
          field.label
        }}</span>
      </v-col>
      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }">
        <span class="text-overline" v-if="!field.required && field.label">
          {{ $t("addAuthorization.fields.optional") }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{
          field.description
        }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          ref="fieldValidationForm"
          hide-details="auto"
          outlined
          dense
          :rules="[...fieldRules, rules.email]"
          :prefix="field.prefix"
          :suffix="field.suffix"
          @input="changeInput"
          :placeholder="field.placeholder"
          :hint="field.helpText"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { inputRules } from "@/utils";
export default {
  name: "EmailField",
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: "col-12",
        min: null,
        max: null,
        defaultValue: null,
      }),
    },
  },
  data() {
    return {
      rules: inputRules,
      fieldValue: null,
    };
  },
  mounted() {
    this.fieldValue = null;

    if (this.field.defaultValue) {
      this.fieldValue = this.formattedAmount(this.field.defaultValue, 2);
      this.changeInput(this.fieldValue.replace(/,/g, ""));
    }
  },
  methods: {
    validate() {
      if (!this.$refs.fieldValidationForm.validate(true)) {
        return false;
      }
      return true;
    },
    changeInput(e) {
      this.$emit("input", e);
    },
    formattedAmount(amount, precission) {
      let userLocale;
      const parsedAmount = parseFloat(amount.toString().replace(/,/g, ""));
      const currentLocale = this.$i18n.locale;
      const currentLocaleIsCustom = currentLocale.indexOf("-") > -1;
      if (currentLocaleIsCustom) {
        userLocale = currentLocale;
      } else {
        userLocale = currentLocale + "-" + this.countryCode;
      }

      return isNaN(parsedAmount)
        ? "0.00"
        : parsedAmount.toLocaleString(userLocale, {
            minimumFractionDigits: precission,
            maximumFractionDigits: precission,
          });
    },
  },
  computed: {
    fieldRules() {
      const fieldRulesArray = [];
      if (this.field.required) {
        fieldRulesArray.push(this.rules.required);
      }

      if (this.field.min === 0 && this.field.max > 0) {
        fieldRulesArray.push(
          this.rules.maxAndMinWithZeroValue(this.field.min, this.field.max)
        );
      } else {
        if (this.field.max) {
          fieldRulesArray.push(this.rules.maxInputValue(this.field.max));
        }

        if (this.field.min) {
          fieldRulesArray.push(this.rules.minInputValue(this.field.min));
        }
      }

      return fieldRulesArray;
    },
  },
  watch: {
    defaultValue() {
      if (this.field.defaultValue) {
        this.fieldValue = this.formattedAmount(this.field.defaultValue, 2);
        this.changeInput(this.fieldValue.replace(/,/g, ""));
      } else {
        this.fieldValue = null;
        this.changeInput(null);
      }
    },
  },
};
</script>
